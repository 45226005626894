import React, { useEffect } from 'react';
import Layout from '../../components/layout';
import SEO from "../../components/seo";
import PGSideBar from '../../components/pg-side-bar';

import '../../styles/cus.css';

const PatentGuide_HowProvisionalBuilderWork = () => {
    const seo = {
        metaDesc: 'How does ProvisionalBuilder™ work? - Inventiv.org'
    }

    useEffect(() => {
        document?.body.classList.add('no-home');
    }, []);

    return (
        <Layout>
            <SEO title="How does ProvisionalBuilder™ work? - Inventiv.org" canonical='/patent-guide' seo={seo} />
            <div class="sub-banner main-banner">
                <div class="container">
                    <div class="breadcrumb-area" style={{ top: 30 + '%' }}>
                        <h1 className='fw-special-title'>&nbsp;</h1>
                        <h1 className='title-banner'>How does ProvisionalBuilder™ work?</h1>
                    </div>
                </div>
            </div>

            <section class="mt-3 mb-3 pt-3 pb-3 wow fadeInUp delay-04s">
                <div class="container ">
                    <div className='row'>
                        <div className='col-md-8 col-sm-12'>
                            <figure className='text-center'>
                                <img width="300" height="300"
                                    src="https://sp-ao.shortpixel.ai/client/to_avif,q_glossy,ret_img,w_300,h_300/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-300x300.png"
                                    data-src="https://sp-ao.shortpixel.ai/client/to_avif,q_glossy,ret_img,w_300,h_300/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-300x300.png"
                                    class="vc_single_image-img attachment-medium lazyloaded" alt="" decoding="async"
                                    data-srcset="https://sp-ao.shortpixel.ai/client/to_avif,q_glossy,ret_img,w_300/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-300x300.png 300w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_150/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-150x150.png 150w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_100/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-100x100.png 100w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_512/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1.png 512w"
                                    data-sizes="(max-width: 300px) 100vw, 300px" data-pagespeed-url-hash="2358126750" sizes="(max-width: 300px) 100vw, 300px"
                                    srcset="https://sp-ao.shortpixel.ai/client/to_avif,q_glossy,ret_img,w_300/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-300x300.png 300w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_150/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-150x150.png 150w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_100/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-100x100.png 100w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_512/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1.png 512w" />
                            </figure>
                            <div class="wpb_wrapper">
                                <h3>How does ProvisionalBuilder™ work?</h3>
                                <p><span style={{ fontWeight: 400 }}>The PPA is completed through a five-step process:</span></p>
                                <ol>
                                    <li><span style={{ fontWeight: 400 }}> Capture an overview of the invention.&nbsp; Enter text into the “title,”
                                        “background of the invention,” “summary of the preferred embodiments,” and “advantages of the preferred
                                        embodiments” fields. For these fields, the software shows you sample patents so you can imitate their
                                        wording if relevant to your invention.</span></li>
                                    <li><span style={{ fontWeight: 400 }}> Capture drawings and generate detailed descriptions for each
                                        drawing.</span></li>
                                    <li><span style={{ fontWeight: 400 }}> Fill out the PPA cover sheet.</span></li>
                                    <li><span style={{ fontWeight: 400 }}> Run a diagnostic on your application text.</span></li>
                                    <li><span style={{ fontWeight: 400 }}> Review and file your PPA.</span></li>
                                </ol>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                            </div>
                            <div className='mt-5'>
                                <p>
                                    <a href='/inventor-resources' className='btn btn-warning rounded-pill px-5'>Download Provisional Patent Assistant Now!</a>
                                </p>
                            </div>
                        </div>

                        <div className='col-md-4 col-sm-12'>
                            <PGSideBar></PGSideBar>
                        </div>
                    </div>

                </div>
            </section>

        </Layout>
    )
};

export default PatentGuide_HowProvisionalBuilderWork;